import React, { useEffect, useState } from 'react';
import './health.css';

const StatusPage = () => {
  const [services, setServices] = useState([
    { name: "CHAT", status: "Operational" },
    { name: "ADMIN", status: "Operational" },
    { name: "API", status: "Operational" },
  ]);

  // Function to check the status of each service
  const checkServiceStatus = async () => {
    const serviceUrls = {
      API: "https://api.addvaluemachine.avm.technology/health",
      ADMIN: "https://admin.addvaluemachine.avm.technology/",
      CHAT: "https://chat.addvaluemachine.avm.technology/",
    };

    const updatedServices = await Promise.all(
      services.map(async (service) => {
        try {
          const response = await fetch(serviceUrls[service.name]);
          if (response.status === 200) {
            return { ...service, status: "Operational" };
          } else {
            return { ...service, status: "Not Working" };
          }
        } catch (error) {
          return { ...service, status: "Not Working" };
        }
      })
    );

    setServices(updatedServices);
  };

  useEffect(() => {
    checkServiceStatus(); // Initial check
    const interval = setInterval(checkServiceStatus, 300000); // 5 minutes interval

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  return (
    <div className="status-page">
      <h1>AVM System Status</h1>
      <div className="status-summary">
        <p>{services.every(service => service.status === "Operational") ? "All Systems Operational" : "Some Systems Experiencing Issues"}</p>
      </div>
      <div className="service-status">
        {services.map((service, index) => (
          <div key={index} className={`service ${service.status.replace(" ", "-").toLowerCase()}`}>
            <h2>{service.name}</h2>
            <p>{service.status}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatusPage;
