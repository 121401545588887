import React from 'react';
import './header.css';
import logo from '../../assets/logo/AVMLogoWhite.svg'; // Adjust path if logo is in another folder

const Header = () => {
  return (
    <header className="header">
      <img src={logo} alt="Logo" className="header-logo" />
    </header>
  );
};

export default Header;
