import React from 'react';
import StatusPage from './components/health/StatusPage';
import Header from './components/header/Header';

function App() {
  return (
    <div className="App">
      <Header/>
      <StatusPage />
    </div>
  );
}

export default App;
